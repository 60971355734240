define("discourse/plugins/stemaway-ui-addons/discourse/controllers/aivia-analytics/performance", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/service", "@ember/object", "discourse/lib/ajax"], function (_exports, _controller, _tracking, _service, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PerformanceController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topicScores", [_tracking.tracked], function () {
      return {};
    }))();
    #topicScores = (() => (dt7948.i(this, "topicScores"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selectedTopic", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedTopic = (() => (dt7948.i(this, "selectedTopic"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "filteredUsers", [_tracking.tracked], function () {
      return [];
    }))();
    #filteredUsers = (() => (dt7948.i(this, "filteredUsers"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "filterStatus", [_tracking.tracked], function () {
      return '';
    }))();
    #filterStatus = (() => (dt7948.i(this, "filterStatus"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "scoreThreshold", [_tracking.tracked], function () {
      return '';
    }))();
    #scoreThreshold = (() => (dt7948.i(this, "scoreThreshold"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return true;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "showPopup", [_tracking.tracked], function () {
      return false;
    }))();
    #showPopup = (() => (dt7948.i(this, "showPopup"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "popupData", [_tracking.tracked], function () {
      return null;
    }))();
    #popupData = (() => (dt7948.i(this, "popupData"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "topicStatistics", [_tracking.tracked], function () {
      return null;
    }))();
    #topicStatistics = (() => (dt7948.i(this, "topicStatistics"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "allTags", [_tracking.tracked], function () {
      return [];
    }))();
    #allTags = (() => (dt7948.i(this, "allTags"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "filteredTags", [_tracking.tracked], function () {
      return [];
    }))();
    #filteredTags = (() => (dt7948.i(this, "filteredTags"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "selectedTags", [_tracking.tracked], function () {
      return [];
    }))();
    #selectedTags = (() => (dt7948.i(this, "selectedTags"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "tagSearchQuery", [_tracking.tracked], function () {
      return '';
    }))();
    #tagSearchQuery = (() => (dt7948.i(this, "tagSearchQuery"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "selectedUsers", [_tracking.tracked], function () {
      return [];
    }))();
    #selectedUsers = (() => (dt7948.i(this, "selectedUsers"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "hoveredSubcategory", [_tracking.tracked], function () {
      return null;
    }))();
    #hoveredSubcategory = (() => (dt7948.i(this, "hoveredSubcategory"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "showSubcategories", [_tracking.tracked], function () {
      return false;
    }))();
    #showSubcategories = (() => (dt7948.i(this, "showSubcategories"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "isDropdownOpen", [_tracking.tracked], function () {
      return false;
    }))();
    #isDropdownOpen = (() => (dt7948.i(this, "isDropdownOpen"), void 0))();
    static #_19 = (() => dt7948.g(this.prototype, "showProfileFilters", [_tracking.tracked], function () {
      return false;
    }))();
    #showProfileFilters = (() => (dt7948.i(this, "showProfileFilters"), void 0))();
    static #_20 = (() => dt7948.g(this.prototype, "selectedTopicName", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedTopicName = (() => (dt7948.i(this, "selectedTopicName"), void 0))();
    constructor() {
      super(...arguments);
      this.loadInitialData();
      this.loadTags();
      this.loadSavedTags();
    }
    async loadInitialData() {
      try {
        this.isLoading = true;
        const mergedResult = await (0, _ajax.ajax)("/admin/user/custom_search/fetch-merged-subcategories-and-topic-scores", {
          method: "GET"
        });
        if (!mergedResult || !mergedResult.merged_subcategories) {
          console.error("Error fetching merged data");
          return;
        }
        this.topicScores = mergedResult.merged_subcategories;
        // console.log(this.topicScores);

        // DO NOT auto-select the first topic. 
        // That means "Select a Category" remains if they refresh the page.
        // const first = this.topicScores[0];
        // if (first && first.topics[0]) {
        //   this.setSelectedTopic(first.topics[0].id);
        //   this.selectedTopicName = first.topics[0].name;
        // }
      } catch (error) {
        console.error("Error loading initial data:", error);
      } finally {
        this.isLoading = false;
      }
    }
    async loadTags() {
      try {
        const result = await (0, _ajax.ajax)('/skills', {
          method: 'GET'
        });
        this.allTags = this.flattenSkills(result.skills || []);
      } catch (error) {
        console.error('Error loading tags:', error);
      }
    }
    async loadSavedTags() {
      try {
        const currentUser = Discourse.User.current();
        if (!currentUser || !currentUser.username) {
          console.error('Current user details are not available.');
          return;
        }

        // console.log('Loading tags for username:', currentUser.username);

        const response = await (0, _ajax.ajax)(`/admin/user/custom_search/load-user-tags/${currentUser.username}`, {
          method: 'POST'
        });
        if (response.success) {
          this.selectedTags = Object.values(response.tags || {});
          // console.log('Loaded saved tags:', this.selectedTags);
        } else {
          console.error('Failed to load saved tags:', response.error);
        }
      } catch (error) {
        console.error('Error loading saved tags:', error);
      }
    }
    flattenSkills(skills) {
      const tags = [];
      const traverse = skillList => {
        skillList.forEach(skill => {
          tags.push({
            id: skill.id,
            name: skill.name
          });
          if (skill.skills?.length) traverse(skill.skills);
          if (skill.sub_skills?.length) traverse(skill.sub_skills);
        });
      };
      traverse(skills);
      return Array.from(new Set(tags.map(tag => tag.id))).map(id => tags.find(tag => tag.id === id));
    }
    calculateStatistics(users) {
      const requiredScore = this.siteSettings.evaluation_required_score;
      const totalUsers = users.length;
      const passCount = users.filter(user => {
        const score = parseInt(user.score.split('/')[0], 10);
        return score >= requiredScore;
      }).length;
      const averageScore = totalUsers > 0 ? users.reduce((sum, user) => sum + parseInt(user.score.split('/')[0], 10), 0) / totalUsers : 0;
      return {
        totalUsers,
        passRate: totalUsers > 0 ? (passCount / totalUsers * 100).toFixed(2) : 0,
        averageScore: averageScore.toFixed(2)
      };
    }
    toggleSubcategories() {
      this.showSubcategories = !this.showSubcategories;
    }
    static #_21 = (() => dt7948.n(this.prototype, "toggleSubcategories", [_object.action]))();
    setHoveredSubcategory(subcategory) {
      this.hoveredSubcategory = subcategory;
    }
    static #_22 = (() => dt7948.n(this.prototype, "setHoveredSubcategory", [_object.action]))();
    clearHoveredSubcategory() {
      this.hoveredSubcategory = null;
    }
    static #_23 = (() => dt7948.n(this.prototype, "clearHoveredSubcategory", [_object.action]))();
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
    static #_24 = (() => dt7948.n(this.prototype, "toggleDropdown", [_object.action]))();
    selectTopicAndCloseDropdown(topicId) {
      this.setSelectedTopic(topicId);
      this.isDropdownOpen = false;
    }
    static #_25 = (() => dt7948.n(this.prototype, "selectTopicAndCloseDropdown", [_object.action]))();
    toggleProfileFilters() {
      this.showProfileFilters = !this.showProfileFilters;
    }
    static #_26 = (() => dt7948.n(this.prototype, "toggleProfileFilters", [_object.action]))();
    setSelectedTopic(topicId) {
      this.selectedTopic = topicId;
      let chosenTopicData = null;
      for (let subcat of this.topicScores) {
        chosenTopicData = subcat.topics.find(t => t.id === topicId);
        if (chosenTopicData) break;
      }
      if (chosenTopicData) {
        this.selectedTopicName = chosenTopicData.name;
        this.filteredUsers = chosenTopicData.users || [];
      } else {
        this.selectedTopicName = null;
        this.filteredUsers = [];
      }
    }
    static #_27 = (() => dt7948.n(this.prototype, "setSelectedTopic", [_object.action]))();
    showHoverWindow(subcategory) {
      this.hoveredSubcategory = subcategory;
    }
    static #_28 = (() => dt7948.n(this.prototype, "showHoverWindow", [_object.action]))();
    hideHoverWindow() {
      this.hoveredSubcategory = null;
    }
    static #_29 = (() => dt7948.n(this.prototype, "hideHoverWindow", [_object.action]))();
    filterUsers() {
      let searchValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      const selectedTopicData = this.topicScores.flatMap(subcategory => subcategory.topics).find(topic => topic.id === this.selectedTopic);
      if (!selectedTopicData || !selectedTopicData.users) {
        this.filteredUsers = [];
        return;
      }
      const lowerSearchValue = searchValue.toLowerCase();
      const threshold = parseInt(this.scoreThreshold, 10) || 0;
      this.filteredUsers = selectedTopicData.users.filter(user => {
        const score = parseInt(user.score.split('/')[0], 10);
        const matchesSearch = user.username.toLowerCase().includes(lowerSearchValue);
        const matchesThreshold = score >= threshold;
        let matchesStatus = true;
        if (this.filterStatus === 'pass') {
          matchesStatus = score >= this.siteSettings.evaluation_required_score;
        } else if (this.filterStatus === 'fail') {
          matchesStatus = score < this.siteSettings.evaluation_required_score;
        }
        return matchesSearch && matchesStatus && matchesThreshold;
      });
      this.filteredUsers = this.sortUsersByScoreDescending(this.filteredUsers);
    }
    static #_30 = (() => dt7948.n(this.prototype, "filterUsers", [_object.action]))();
    sortUsersByScoreDescending(users) {
      return users.sort((a, b) => parseInt(b.score.split('/')[0], 10) - parseInt(a.score.split('/')[0], 10));
    }
    updateScoreThreshold(threshold) {
      this.scoreThreshold = threshold;
      this.filterUsers();
    }
    static #_31 = (() => dt7948.n(this.prototype, "updateScoreThreshold", [_object.action]))();
    updateFilterStatus(status) {
      this.filterStatus = status;
      this.filterUsers();
    }
    static #_32 = (() => dt7948.n(this.prototype, "updateFilterStatus", [_object.action]))();
    toggleUserSelection(user) {
      if (this.selectedUsers.includes(user.username)) {
        this.selectedUsers = this.selectedUsers.filter(username => username !== user.username);
      } else {
        this.selectedUsers = [...this.selectedUsers, user.username];
      }
    }
    static #_33 = (() => dt7948.n(this.prototype, "toggleUserSelection", [_object.action]))();
    selectAllUsers() {
      const allUsernames = this.filteredUsers.map(user => user.username);
      this.selectedUsers = allUsernames;
    }
    static #_34 = (() => dt7948.n(this.prototype, "selectAllUsers", [_object.action]))();
    deselectAllUsers() {
      this.selectedUsers = [];
    }
    static #_35 = (() => dt7948.n(this.prototype, "deselectAllUsers", [_object.action]))();
    isUserSelected(user) {
      return this.selectedUsers.includes(user.username);
    }
    static #_36 = (() => dt7948.n(this.prototype, "isUserSelected", [_object.action]))();
    filterTags(query) {
      this.tagSearchQuery = query.toLowerCase();
      this.filteredTags = this.allTags.filter(tag => tag.name.toLowerCase().includes(this.tagSearchQuery));
    }
    static #_37 = (() => dt7948.n(this.prototype, "filterTags", [_object.action]))();
    selectTag(tag) {
      if (!this.selectedTags.find(t => t.id === tag.id)) {
        this.selectedTags = [...this.selectedTags, tag];
      }
      this.tagSearchQuery = '';
      this.filteredTags = [];
    }
    static #_38 = (() => dt7948.n(this.prototype, "selectTag", [_object.action]))();
    deselectTag(tag) {
      this.selectedTags = this.selectedTags.filter(t => t.id !== tag.id);
    }
    static #_39 = (() => dt7948.n(this.prototype, "deselectTag", [_object.action]))();
    async saveSelectedTags() {
      try {
        const currentUser = Discourse.User.current();
        if (!currentUser || !currentUser.username) {
          console.error('Current user details are not available.');
          return;
        }

        // console.log('Saving tags for username:', currentUser.username);

        await (0, _ajax.ajax)(`/admin/user/custom_search/save-user-tags/${currentUser.username}`, {
          method: 'POST',
          data: {
            tags: this.selectedTags
          }
        });
        alert('Tags saved successfully!');
      } catch (error) {
        console.error('Error saving tags:', error);
        alert('Failed to save tags.');
      }
    }
    static #_40 = (() => dt7948.n(this.prototype, "saveSelectedTags", [_object.action]))();
    showEvaluationLogs(user) {
      if (user.evaluation) {
        const feedbackParagraphs = user.evaluation.feedback ? user.evaluation.feedback.split(/(\d+\.\s)/).reduce((acc, item, index, array) => {
          if (item.match(/^\d+\.\s$/)) {
            acc.push(item + (array[index + 1] || '').trim());
          } else if (!array[index - 1]?.match(/^\d+\.\s$/)) {
            acc.push(item.trim());
          }
          return acc;
        }, []) : ['No feedback available.'];
        this.popupData = {
          username: user.username,
          evaluation: user.evaluation,
          feedbackParagraphs
        };
        this.showPopup = true;
      } else {
        this.popupData = {
          username: user.username,
          evaluation: null,
          feedbackParagraphs: ['No evaluation data available.']
        };
        this.showPopup = true;
      }
    }
    static #_41 = (() => dt7948.n(this.prototype, "showEvaluationLogs", [_object.action]))();
    closePopup() {
      this.showPopup = false;
      this.popupData = null;
    }
    static #_42 = (() => dt7948.n(this.prototype, "closePopup", [_object.action]))();
    async sendNotifications() {
      if (this.selectedUsers.length === 0) {
        alert('No users selected for notification.');
        return;
      }
      try {
        const currentUser = Discourse.User.current();
        if (!currentUser || !currentUser.username) {
          throw new Error('Current user details are not available.');
        }
        const title = `Message from ${currentUser.username}`;
        const message = 'This is a sample notification.';

        // console.log('Sending notifications:', { title, message });

        const response = await (0, _ajax.ajax)('/admin/user/custom_search/send-notifications', {
          method: 'POST',
          data: {
            usernames: this.selectedUsers,
            title: title,
            message: message
          }
        });
        if (response.topic_url) {
          alert(`Notifications sent successfully! Topic created: ${response.topic_url}`);
        } else {
          alert('Notifications sent, but topic creation failed.');
        }
      } catch (error) {
        console.error('Error sending notifications:', error);
        alert('Failed to send notifications.');
      }
    }
    static #_43 = (() => dt7948.n(this.prototype, "sendNotifications", [_object.action]))();
  }
  _exports.default = PerformanceController;
});